<template>
    <div class="">
      <slot :scope="{ pagination, fetch, loading }"/>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    props: {
        query: { required: false, type: Object, default: () => ({}) }
    },
    data: () => ({
        timeout: false
    }),
    computed: mapState({
        pagination: ({ students }) => students.pagination,
        loading: state => state.students.loading,
    }),
    methods: {
        ...mapActions({
            fetchStudents: 'students/index',
        }),
        fetch(_query = {}) {
            const query = this.$repositoryUtils.withoutInvalidValues({...this.query, ..._query})
            this.fetchStudents(query)
                .then(() => {
                    this.$router.replace({ query })
                        .catch(() => null)
                })
        }
    },
    watch: {
        query: {
            deep: true,
            handler(val) {

                if (this.timeout) {
                    clearTimeout(this.timeout);
                }

                this.timeout = setTimeout(() => {
                    this.fetch(val)
                }, 1000)
            }
        }
    },
    mounted() {
        this.fetch({ page: this.$route.query.page || 1, ...this.filters })
    }
}
</script>
