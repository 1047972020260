<template>
    <div class="">
        <slot :scope="{ pagination, fetch, loading }" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    props: {
        courseSection: { required: false, type: Object, default: null },
        search: { required: false, type: String, default: null },
        query: { required: false, type: Object, default: () => ({}) }
    },
    data: () => ({
        timeout: null,
        pagination: { data: [] },
        loading: false
    }),
    computed: mapState({
        user: ({ session }) => session.user
    }),
    methods: {
        ...mapActions({
            fetchStudents: 'courseSections/students',
            fetchStudentsAsAdmin: 'students/index',
        }),
        fetch(_query = {}) {
            this.loading = !this.loading
            if (this.user.isTeacher) {
                const query = this.$repositoryUtils.withoutInvalidValues({
                    ...this.courseSection,
                    filters: {...this.query, ..._query}, context: 'pagination'
                })
                this.fetchStudents(query)
                    .then(response => {
                        this.pagination = response.data
                        this.$router.replace({ query })
                            .catch(() => null)
                    })
                    .finally(() => this.loading = !this.loading)
            } else {
                const query = this.$repositoryUtils.withoutInvalidValues({
                    ...this.query,
                    ..._query
                })
                this.fetchStudentsAsAdmin(query)
                    .then(pagination => {
                        this.pagination = pagination
                        this.$router.replace({ query })
                            .catch(() => null)
                    })
                    .finally(() => this.loading = !this.loading)
            }
        }
    },
    watch: {
        query: {
            deep: true,
            handler(val) {
                if (this.user.isCoordinator) {
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                    }

                    this.timeout = setTimeout(() => {
                        this.fetch(val)
                    }, 1000)
                }
            }
        },
        search(search) {
            if (this.user.isTeacher) {
                if (this.timeout) {
                    clearTimeout(this.timeout)
                }

                if (search !== null && this.courseSection) {
                    this.timeout = setTimeout(() => {
                        this.fetch()
                    }, 1000)
                }
            }
        },
        courseSection(val) {
            if (val && this.user.isTeacher) {
                this.fetch()
            }
        }
    },
}
</script>
