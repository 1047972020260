<template>
  <div>
    <div class="flex justify-between items-center mb-2 flex-wrap">
      <h3 class="text-2xl">Estudiantes</h3>
      <div>
<!--        <router-link :to="{ name: 'students.withdrawals.index' }" class="mr-2">Listado de retiros</router-link>-->
<!--        <a-button outlined @click="$router.push({ name: 'students.withdrawals.index' })" class="mr-2">Listado de retiros</a-button>-->
<!--        <a-button outlined @click="clearFilters" v-if="user.isAdminGroup" class="w-full md:w-auto lg:w-auto">Limpiar filtros de búsqueda</a-button>-->
      </div>
    </div>

    <div class="flex justify-between flex-wrap my-4 items-end bg-white p-2 rounded-2xl shadow-md space-y-2">
      <div v-if="!user.isAdmin" />
      <template v-if="user.isAdminGroup || user.isCoordinator">
        <div class="w-1/2 xl:w-1/4 px-2">
          <a-select
            label="Período"
            :source="periods.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))"
            :loading="loadings.periods"
            @input="clearBy(['specialization', 'semester', 'section'])"
            v-model="query.period" />
        </div>
        <div class="w-1/2 xl:w-1/4 px-2" v-if="user.isAdminGroup">
          <a-select
            label="Especialidad"
            :source="specializations.map($0 => ({ label: $0.title, value: $0.id }))"
            :loading="loadings.periods"
            @input="clearBy(['semester', 'section'])"
            v-model="query.specialization" />
        </div>
        <div class="w-1/2 xl:w-1/4 px-2">
          <a-select
            label="Semestre"
            :source="semesters.map($0 => ({ label: $0.number, value: $0.id }))"
            :loading="loadings.periods"
            @input="clearBy(['section'])"
            v-model="query.semester" />
        </div>
        <div class="w-1/2 xl:w-1/4 px-2">
          <a-select
            label="Sección"
            :source="sections.map($0 => ({ label: $0.letter_code, value: $0.id }))"
            :loading="loadings.periods"
            v-model="query.section" />
        </div>
        <div class="w-1/2 xl:w-1/4 px-2" v-if="user.isCoordinator">
          <a-select
            label="Materia"
            :source="courses.map($0 => ({ label: $0.title, value: $0.id }))"
            :loading="loadings.periods"
            v-model="query.course" />
        </div>
      </template>
      <template v-else>
        <div class="flex space-x-2">
          <a-select
            class="w-48"
            label="Período"
            filterable
            clearable
            placeholder="Período"
            :source="periods.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))"
            v-model="teacherPeriod"
            :disabled="loadings.periods"
            :loading="loadings.periods" />

          <a-select
            class="w-72"
            label="Materia / Sección"
            filterable
            clearable
            placeholder="Materia / Sección"
            :source="courseSections.map($0 => ({ label: `${$0.course.title} - ${$0.section.letter_code}`, value: { course_id: $0.course.id, section_id: $0.section.id } }))"
            v-model="courseSection"
            :disabled="loadings.courses"
            :loading="loadings.courses" />
        </div>
      </template>

      <div class="px-2 flex justify-between w-full items-center">
        <div class="flex justify-start px-2">
          <a-input is-search placeholder="Buscar..." v-model="query.search" class="flex justify-start mr-2"/>
          <a-button outlined @click="clearFilters" v-if="user.isAdminGroup" class="flex items-center mt-2 h-10">Limpiar filtros de búsqueda</a-button>   
        </div>
        <div class="flex justify-end px-2">
          <a-button outlined target="_blank" :href="`${url}/api/generate-report`" v-if="user.isAdminGroup" class="mt-1 border-blue-700">Imprimir lista de graduandos</a-button>
        </div>
      </div>
    </div>
    <component :is="user.isAdminGroup ? 'admin-list' : 'teacher-list'" :course-section="courseSection" :query="query" :search="query.search">
      <template #default="{ scope: { pagination, fetch, loading } }">

        <template v-if="loading">
          <div class="-mx-2 flex flex-wrap mt-4">
            <div class="px-2 w-1/2" v-for="n in 4" :key="n">
              <a-skeleton class="w-full h-20 rounded-2xl"></a-skeleton>
            </div>
          </div>
        </template>

        <template v-else-if="!loading && pagination.data.length > 0">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <student-card
              v-for="(it, k) in pagination.data" :key="k"
              :course-section="courseSection"
              :loading-sections="loadings.periods"
              :user="user"
              :sections="studentSections"
              :query="query"
              :student="it"
              @set-student-notification="student_notificatons = $event"
              @set-student="student = $event" >
            </student-card>
          </div>
        </template>

        <template v-else>
          <div class="flex flex-col space-y-2 p-16">
            <h3 class="text-center text-2xl" v-if="user.isTeacher || user.isCoordinator">
              Por favor seleccione una materia
            </h3>
            <template v-else>
              <h1 class="text-2xl text-center">No hay datos encontrados</h1>
              <span class="text-center">
                  <button class="text-primary p-2 px-4 focus:outline-none focus:ring-2 focus:ring-primary inline-flex items-center justify-center rounded-full" @click="fetch(query)">
                      Reintentar
                  </button>
              </span>
            </template>
          </div>
        </template>

        <a-paginate
          :current="pagination.current_page"
          :total="pagination.total"
          @page-changed="fetch({ page: $event })"
          :per-page="pagination.per_page"
          class="mt-3"/>

      </template>
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import modalBinnacles from '@/views/binnacles/modalBinnacles';
import modalNotifications from '@/views/notifications/modalNotifications';
import StudentCard from './components/student-card';
import AdminList from './components/admin-list.vue';
import TeacherList from './components/teacher-list.vue';
import {URL} from '@/constants';

export default {
  components: {
    modalBinnacles,
    modalNotifications,
    StudentCard,
    AdminList,
    TeacherList
  },
  data: () => ({
    url: URL,
    periods: [],
    teacherPeriod: null,
    courseSections: [],
    studentSections: [],
    courseSection: {
      course_id: null,
      section_id: null
    },
    query: {
      limit: 10,
      search: '',
      period: null,
      specialization: null,
      semester: null,
      section: null,
      course: null,
      sort_by: 'last_name,asc',
      with: 'latestInscription.period,latestInscription.specialization,latestInscription.semester,latestInscription.section,inscriptions.semester',
      retired: 0,
    },
    loadings: {
      periods: false,
      courses: false,
      sections: false
    }
  }),
  computed: {
    ...mapState({
      user: state => state.session.user
    }),
    period() {
      return this.periods.find($0 => $0.id === this.query.period)
    },
    pensum() {
      return this.period?.pensums.find($0 => $0.specialization_id === this.query.specialization)
    },
    specialization() {
      return this.specializations.find($0 => $0.id === this.query.specialization)
    },
    semester() {
      return this.semesters.find($0 => $0.id === this.query.semester) ?? { courses: [] }
    },
    specializations() {
      return (this.period?.pensums ?? []).map($0 => $0.specialization)
        .filter(($0, index, self) => self.findIndex($1 => $1.id === $0.id) === index)
    },
    semesters() {
      return this.pensum?.semesters ?? []
    },
    section() {
      return this.sections.find($0 => $0.id === this.query.section)
    },
    sections() {
      return (this.semester?.sections ?? [])
        .filter($0 => $0.period_id === this.query.period)
    },
    courses() {
      return this.section?.courses ?? []
    }
  },
  watch: {
    teacherPeriod(period) {
      this.query.period = period
      if (period) {
        this.$repository.teachers
          .courses(this.user.teacher.id, { limit: 0, period })
          .then(({ data }) => {
            this.courseSections = data
            return this.courseSections = data
          })
          ._loading(() => this.loadings.courses = !this.loadings.courses)
      }
    },
    courseSection(value){
      this.query.section = value.section_id
      this.query.course = value.course_id
    }
  },
  methods: {
    clearFilters() {
      Object.keys(this.query).forEach($0 => {
        if (!['with', 'limit', 'sort_by'].includes($0)) {
          this.query[$0] = null
        }
      })
      this.query.search = null
      this.courseSection = { course_id: null, section_id: null }
      this.teacherPeriod = null
    },
    clearBy(keys) {
      keys.forEach($0 => {
        if (!this.user.isCoordinator && $0 === 'specialization') {
          this.query[$0] = null
        }
      })
    }
  },
  mounted() {
    if (this.user.isAdminGroup || this.user.isCoordinator) {

      this.query.search = this.$route.query.search ? this.$route.query.search : ''

      if (this.user.isCoordinator) {
        this.query.specialization = this.user.teacher.specialization_id
      }

      this.$repository.periods
        .index({
          limit: 0,
          sort_by: 'year,asc',
          with: `pensums.specialization,pensums.semesters.sections.courses,`
        }, { cancellable: false })
        .then(({ data }) => this.periods = data)
        ._loading(() => this.loadings.periods = !this.loadings.periods)

      this.$repository.sections
        .index({ limit: 0, with: 'specialization,period,semester' }, { cancellable: false })
        .then(({ data }) => this.studentSections = data)
        ._loading(() => this.loadings.sections = !this.loadings.sections)
    } else {
      this.$repository.periods
        .index({ limit: 0, sort_by: 'year,asc' })
        .then(({ data }) => this.periods = data)
        ._loading(() => this.loadings.periods = !this.loadings.periods)
    }
  }
}
</script>
