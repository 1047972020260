<template>
  <a-card class="p-4">
    <div class="flex w-full flex-wrap justify-center md:justify-start lg:justify-start">
      <a-avatar
        :text="student.last_name"
        size="64px"
        class="rounded-full mx-auto md:mx-0 md:mr-6 w-full md:w-16 lg:w-16 text-center" />

      <div class="flex flex-col items-center md:items-start lg:items-start" style="flex-grow: 1;">
        <p class="text-lg w-auto md:w-full lg:w-full dark:text-white">
          <b class="dark:text-white">Nombres</b>: {{ student.last_name + ', ' }} {{ student.first_name }}
        </p>
        <p class="text-md w-auto md:w-full lg:w-full dark:text-white">
          <b class="dark:text-white">Email</b>: {{ student.email }}
        </p>
      <p class="text-md w-auto md:w-full lg:w-full dark:text-white">
          <b class="dark:text-white">Email IDC</b>: {{ student.institution_email ? student.institution_email : '---' }}
        </p>
        <p class="text-md w-auto md:w-full lg:w-full dark:text-white">
          <b class="dark:text-white">Cédula</b>: {{ student.id_document }}
        </p>
      </div>
      <div class="flex flex-wrap -mx-2 w-full mt-3">
        <div class="px-2 w-1/2">
          <p class="dark:text-white">
            <b class="dark:text-white">Género</b>: {{ student.gender === 'male' ? 'Masculino' : 'Femenino' }}
          </p>
          <template v-if="!user.isTeacher">
            <p class="dark:text-white">
              <b class="dark:text-white">Período</b>: {{ findPeriod(student.latest_inscription.period) }}
            </p>
            <p class="dark:text-white" v-if="!user.isTeacher">
              <b class="dark:text-white">Semestre</b>: {{ student.inscriptions.filter((a) => a.period_id === student.latest_inscription.period_id).map((a) => a.semester.number).sort((a, b) => a - b).join(' - ') }}
            </p>
          </template>
        </div>
        <div class="px-2 w-1/2 items-end flex flex-col">
          <p class="dark:text-white">
            <b class="dark:text-white">Turno</b>: {{ getSheduleShift(student.schedule_shift) }}
          </p>
          <template v-if="!user.isTeacher">
            <p v-if="student.latest_inscription.section" class="dark:text-white">
              <b class="dark:text-white">Sección</b>: {{ student.latest_inscription.section.letter_code }}
            </p>
          </template>

        </div>
        <div class="px-2" v-if="!user.isTeacher">
          <p class="dark:text-white">
            <b class="dark:text-white">Fecha de inscripción</b>: {{ student.latest_inscription.created_at | date }}
          </p>
          <p class="dark:text-white">
            <b class="dark:text-white">Especialidad</b>:
            {{ student.latest_inscription.specialization.title }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-3 items-center md:items-end lg:items-end">
      <div v-if="!user.isStudent" class="flex flex-wrap space-x-4">
        <router-link
          v-tooltip="`Ver estudiante`"
          class="text-primary"
          v-if="user.isAdminGroup || user.isTeacherGroup || (courseSection.course_id && courseSection.section_id)"
          :to="studentsLink">
          <a-icon name="eye" />
        </router-link>
        <a-dropdown right v-if="user.isAdmin && student.latest_inscription.section">
          <button
            class="text-primary"
            v-tooltip="`Cambiar de sección`">
            <a-icon name="exchange" />
          </button>
          <template #menu>
            <a-dropdown-item v-if="loadingSections">
              <a-loader class="mr-2 h-6 w-6" /> Cargando...
            </a-dropdown-item>
            <a-dropdown-item v-else-if="!mappedSections.length">
              No hay secciones disponibles
            </a-dropdown-item>
            <template v-else>
              <a-dropdown-item v-for="section in mappedSections" :key="section.id" class="w-full" @click="changeSection({ student, section, context: 'pagination' })">
                {{ section.specialization.title }} - {{ section.letter_code }}
              </a-dropdown-item>
            </template>
          </template>
        </a-dropdown>
      </div>
    </div>
  </a-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: {
        user: { required: true, type: Object },
        student: { required: true, type: Object },
        query: { required: true, type: Object },
        sections: { required: false, type: Array, default: () => [] },
        loadingSections: { required: false, type: Boolean, default: false },
        courseSection: { required: false, type: Object, default: () => ({}) }
    },
    computed: {
        mappedSections() {
            const {
              specialization_id,
              period_id,
              semester,
              section
            } = this.student.latest_inscription

            return this.sections.filter($0 => $0.specialization_id === specialization_id
              && $0.period_id === period_id
              && $0.semester.number === semester.number
              && $0.id !== section.id
            )
        },
        studentsLink() {
            let baseRoute = { name: 'student', params: { student: parseInt(this.student.id) } }
            if (this.user.isCoordinator) {
                baseRoute = { name: 'student', params: { student: parseInt(this.student.id) } }
            }
            if (this.user.isTeacher) {
                baseRoute.name = 'section_course_student'
                baseRoute.params = {
                  ...baseRoute.params,
                  course: this.courseSection.course_id,
                  section: this.courseSection.section_id,
                }
            }

            return baseRoute
        }
    },
    methods: {
        ...mapActions({
            changeSection: 'students/changeSection'
        }),
        findPeriod(period) {
            // if (period) {
            //     return `${period.year}-${period.number}`
            // }
            // return '-'
          return period ? `${period.year}-${period.number}` : '-'
        },
        getSheduleShift(shift) {
           if (shift === 'morning') return 'Mañana';
           if (shift === 'afternoon') return 'Tarde';
           return 'Mixto'
        },
        // mappedSections(student) {
        //     const {
        //       specialization_id,
        //       period_id,
        //       semester
        //     } = student.latest_inscription
        //     // const {
        //     //     specialization_id,
        //     //     period_id,
        //     //     semester
        //     // } = student.latest_inscription
        //
        //     return this.sections.filter($0 =>{
        //         console.log($0.specialization_id === specialization_id
        //           && $0.period_id === period_id
        //           && $0.semester.number === semester.number
        //           && $0.id !== student.latest_inscription.section.id)
        //         return $0.specialization_id === specialization_id
        //             && $0.period_id === period_id
        //             && $0.semester.number === semester.number
        //             && $0.id !== student.latest_inscription.section.id
        //     }
        //         // $0.specialization_id === specialization_id
        //         // && $0.period_id === period_id
        //         // && $0.semester.number === semester.number
        //         // && $0.id !== student.latest_inscription.section.id
        //     )
        // },
    }
}
</script>
