<template>
	<div class="p-1" style="min-width: 100%;">
        <a-card v-if="!form">
            <template #header>
                <div class="flex justify-between mb-4 -mt-4">
                    <h3>{{ displayingNotifications }} {{ student.first_name }} {{ student.last_name }}</h3>
                </div>
                
                <div class="flex flex-wrap">
                    <div class="flex flex-wrap w-full justify-end -mx-2 mb-2 justify-end">
                        <div class="w-full md:w-1/2 lg:w-1/2 flex">
                            <a-segmented-control v-model="control" :segments="segments" />
                        </div>
                        <div class="w-full md:w-1/2 lg:w-1/2 flex justify-end mt-2 md:mt-0 lg:mt-0">
                            <a-button :disabled="loading" :loading="loading" v-if="!user.isStudent" @click="form = true" class="w-full md:w-auto lg:w-auto">
                                Crear notificación
                            </a-button>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="!loading">
                <div class="md:flex bg-white rounded-lg p-6 shadow-md mt-2 flex-col" v-for="notification in pagination.data" :key="notification.id">
                    <div class="flex w-full flex-wrap justify-center md:justify-start lg:justify-start">
                        <a-avatar
                            :text="notification.sender.name"
                            :picture="control === 1 ? notification.sender.profile_picture : student.profile_picture"
                            size="64px"
                            class="rounded-full mx-auto md:mx-0 md:mr-6 w-full md:w-16 lg:w-16 text-center" />
                        <div class="flex flex-col items-center md:items-start lg:items-start">
                            <p class="text-lg w-auto">
                                De: <b>{{ notification.sender.email }}</b> <br>
                            </p>
                            <p class="text-lg w-auto">
                                Para: <b>{{ student.email }}</b>
                            </p>
                        </div>
                    </div>
                    <p class="text-gray-600 text-md w-full mt-2">
                        Contenido: {{ notification.content }}
                    </p>
                    <p class="mt-2 text-gray-600 text-md w-full mt-2" v-if="notification.attachment">
                        Archivo adjunto: <a class="text-blue-600 underline" :href="notification.attachment" target="_blank">Ver archivo</a>
                    </p>
                    <div class="flex flex-col items-center md:items-end lg:items-end">
                        <a-button class="w-full sm:w-auto lg:w-auto mt-2" flat style="color: #FF4600;" @click="deleteNotification(notification.id)">
                            Eliminar
                        </a-button>
                    </div>
                </div>
                <a-laravel-pagination align="right" :data="pagination" @change="handle({ ...query, page: $event })" class="mt-3"/>
            </template>
            <a-skeleton v-else height="60px"></a-skeleton>
        </a-card>

        <div  v-if="form" class="flex">
            <div class="w-full">

                <form @submit.prevent="store()" @keydown="removeError($event.target.id)">
                    <a-card>
                        <h3>Crear notificación</h3>
                        <div  style=" padding: 20px 0px; font-weight: bold; text-align: center; font-size: 20px;">
                            {{ student.first_name }} {{ student.last_name }}
                        </div>
                        <div class="flex flex-wrap -mx-2">
                            <div class="w-full">
                                <div class="form-group">
                                    <label for="content">Contenido</label>
                                    <a-input
                                        id="content"
                                        :error="error('content')"
                                        v-model="notification.content" />
                                </div>

                                <div class="form-group">
                                    <label for="attachment">Archivo</label>
                                    <a-upload-input
                                        id="attachment"
                                        :error="error('attachment')"
                                        v-model="notification.attachment"/>
                                </div>

                            </div>
                        </div>

                        <template #footer>
                            <a-button class="mr-2" type="submit" :loading="loading">
                                Enviar
                            </a-button>

                            <a-button outlined @click="() => {
                                    form = false
                                    control = 0
                                }">
                                Cancelar
                            </a-button>
                        </template>
                    </a-card>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
export default {
    props: {
        student: { required: false, type: Object, defauklt: null },
        updateData: { required: false, type: Object, defauklt: null }
    },
    data: () => ({
        control: 0,
        form: false,
        columns: [
            { title: 'De: ', key: 'sender.email', enabled: true },
            { title: 'Contenido: ', key: 'content', enabled: true },
            { title: 'Archivo', slot: 'attachment', enabled: true },
            { title: 'Acciones', slot: 'actions', alignment: 'right', enabled: true },
        ],
        segments: [
            { id: 0, title: "Recibidas" },
            { id: 1, title: "Enviadas" },
        ],
        query: {
            limit: 10,
            search: '',
            with: 'sender',
            page: 1
        },
        timeout: null,
        notification: {
            content: '',
            type: 7,
            attachment: null,
            receiver_user_id: null,
            back:''
        }
    }),
    computed: {
        ...mapState({
            user: state => state.session.user,
            pagination: state => state.notifications.pagination,
            loading: state => state.notifications.loading
        }),
        ...mapGetters({
            hasError: 'hasError',
            error: 'error'
        }),
        displayingNotifications() {
            if (this.control === 1) {
                return 'Notificaciones enviadas a'
            }

            return 'Notificaciones recibidas de'
        }
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchNotifications: 'notifications/fetchStudentNotifications',
            deleteNotification: 'notifications/delete',
            storeNotification: 'notifications/store',
            removeError: 'removeError',
        }),
        handle(query) {
            this.fetchNotifications({ 
                    id: this.student.id, 
                    filters: query
                })
        },
        handleTypingSearch() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.fetchNotifications({ 
                    id:this.student.id, 
                    filters: { ...this.query }
                })
            }, 1000);
        },
        toggleQueryFilters(query) {
            this.fetchNotifications({ 
                id: this.control === 1 ? this.student.id : this.user.id,
                filters: { ...query }
            })
        },
        store(){
            this.storeNotification(this.notification).then(() => {
                this.fetchNotifications({
                    id: this.student.id, filters:{ ...this.query }
                });

                this.form = false;
            })
        }
    },
    watch: {
        control(val) {

            let params = { with: 'sender' }

            if (val === 1) {
                params.sent = 'true'
            }

            params = {
                ...this.query,
                ...params
            }

            this.toggleQueryFilters(params)
        }
    },
    mounted() {
        this.notification.receiver_user_id = this.student.user_id;
        
        this.fetchNotifications({ 
            id: this.control === 1 ? this.student.id : this.user.id,
            filters:{ ...this.query } 
        })
        
    }
}
</script>